<template>
  <div class="chat-container">
    <div class="chat-main">
      <div class="chat-video" id="remoteStream"></div>
    </div>
    <div class="chat-self">
      <span class="item-icon">
        <svg-icon icon-class="camera_off" />
      </span>
      <div class="chat-video" id="localStream"></div>
    </div>
    <a class="chat-btn chat-switch" v-if="localStream && cameras.length > 1" @click="switchCamera">
      <svg-icon icon-class="camera_switch" />
    </a>
    <div class="chat-btns" v-if="inited">
      <a class="chat-btn" @click="togglePublish">
        <svg-icon :icon-class="localStream ? 'camera' : 'camera_off'" />
      </a>
      <a class="chat-btn danger" @click="handleOff">
        <svg-icon icon-class="call" />
      </a>
      <a class="chat-btn" @click="toggleAudio">
        <svg-icon :icon-class="isMute ? 'micphone_off' : 'micphone'" />
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line no-unused-vars */
//import TRTC from 'trtc-js-sdk'
import $scriptjs from 'scriptjs'
import { mapState } from 'vuex'
//import room from '@/api/room'

const sdkAppId = 1400522566;

export default {
  name: 'Chat',
  data: function () {
    return {
      roomId: '',
      isJoinedRoom: false,
      localStream: null,
      remoteStream: null,
      isMute: false,
      cameras: [],
      currentCameraDeviceId: null,
      inited: false,
      userSig: '',
    };
  },
  computed: {
    ...mapState(['user']),
    roomStatus: function () {
      return this.isJoinedRoom ? '已加入' : '未加入';
    },
  },
  created: function () {
    if (!sdkAppId) {
      alert('请先设置 sdkAppId');
      return;
    }
    const { room_id } = this.$route.params
    this.roomId = room_id
    if (!room_id) {
      this.$toast('房间不存在')
      return;
    }
  },
  mounted: function () {
    $scriptjs(['/static/js/lib-generate-test-usersig.min.js', '/static/js/trtc.js'], () => {
      /* eslint-disable-next-line no-undef */
      const generator = new LibGenerateTestUserSig(sdkAppId, '9f16dc51388dd3aa5b1be02ca8507b7ca4658dac0049d0369aaf133900be848c', 604800);
      this.userSig = generator.genTestUserSig(this.user.uuid);
      this.init()
    })
  },
  beforeDestroy: function () {
    console.info('component will destroy');
    window.removeEventListener('beforeunload', this.handleLeaveRoom);
    this.handleLeaveRoom();
  },
  destroyed: function () {
    this.isComponentDestroyed = true;
  },
  methods: {
    async init() {
      //const token = await room.entry(this.roomId)
      TRTC.getCameras().then(devices => {
        devices.forEach(device => {
          if (!this.currentCameraDeviceId) {
            this.currentCameraDeviceId = device.deviceId
          }
        })
        this.cameras = devices
      })
      this.inited = true
      this.client = new TRTC.createClient({
        mode: 'rtc',
        sdkAppId,
        userId: this.user.uuid,
        userSig: this.userSig,
        useStringRoomId: true,
      })

      this.client.on('stream-added', event => {
        console.log('stream-added: ', event.stream);
        this.remoteStream = event.stream
        // 自动订阅
        this.client.subscribe(event.stream);
      });
      this.client.on('stream-subscribed', event => {
        console.info('stream-subscribed: ', event.stream);
        const remoteStream = event.stream
        remoteStream.play('remoteStream')
      });
      this.client.on('stream-removed', (event) => {
        console.info('stream-removed: ', event.stream);
        const remoteStream = event.stream
        remoteStream.stop()
        document.getElementById('remoteStream').innerHTML = ''
      });
      this.client.on('connection-state-change', ({ previous, current }) => {
        console.log(`连接状态 ${previous} -> ${current}`);
      });
      this.client.on('stream-reconnected', ({ current }) => {
        console.log(`流已断开重连`);
        this.$nextTick(() => {
          this.client.play({
            streamId: current.sid,
            container: current.sid
          });
        });
      });

      window.addEventListener('beforeunload', this.handleLeaveRoom);

      this.handleJoinRoom()
    },
    handleJoinRoom: function () {
      const { roomId, isJoinedRoom } = this;
      if (isJoinedRoom) {
        alert('已经加入了房间');
        return;
      }
      if (!roomId) {
        alert('请先填写房间号');
        return;
      }
      console.log(roomId)
      this.client.join({ roomId })
      .catch(err => {
        console.error('加入房间失败： ', err);
      })
      .then(() => {
        console.log('加入房间成功')
        this.handlePublish()
      })
    },
    handlePublish: function () {
      const localStream = TRTC.createStream({
        userId: this.user.uuid,
        audio: true,
        video: true
      })
      this.localStream = localStream
      localStream.initialize()
      .catch(error => {
        console.error('初始化本地流失败 ' + error);
      })
      .then(() => {
        console.log('初始化本地流成功');
        localStream.play('localStream')

        this.client.publish(localStream)
        .catch(error => {
          console.error('本地流发布失败 ' + error);
        })
        .then(() => {
          console.log('本地流发布成功');
        });
      });
    },
    handlePublishScreen: function () {
      this.client.publish({ audio: false, video: false, screen: true }, (err) => {
        console.error(`发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`);
      });
    },
    toggleAudio() {
      if (this.isMute) {
        this.localStream && this.client.unmuteAudio(this.localStream.sid)
      }
      else {
        this.localStream && this.client.muteAudio(this.localStream.sid)
      }
      this.isMute = !this.isMute
    },
    togglePublish() {
      if (!this.localStream) {
        this.handlePublish()
      }
      else {
        this.handleUnpublish()
      }
    },
    handleUnpublish: function () {
      const { localStream } = this;
      if (!localStream) {
        //alert('未选择需要取消发布的本地流');
        return;
      }
      this.client.unpublish(localStream.sid, (stream) => {
        console.info('取消发布本地流成功：', stream);
        this.localStream = null
      }, (err) => {
        console.error('取消发布本地流失败：', err);
      });
    },
    handleSubscribe: function () {
      const { remoteStream } = this;
      if (!remoteStream) {
        alert('未选择需要订阅的远端流');
        return;
      }
      this.client.subscribe(remoteStream.sid, (err) => {
        console.error('订阅失败：', err);
      });
    },
    handleUnsubscribe: function () {
      const { remoteStream } = this;
      if (!remoteStream) {
        alert('未选择需要取消订阅的远端流');
        return;
      }
      this.client.unsubscribe(remoteStream.sid, (stream) => {
        console.info('取消订阅成功：', stream);
        this.remoteStream = null
      }, (err) => {
        console.error('订阅失败：', err);
      });
    },
    handleLeaveRoom: function () {
      const { isJoinedRoom } = this;
      if (!isJoinedRoom) {
        return;
      }
      this.client.leaveRoom(() => {
        console.info('离开房间成功');
        this.localStream = null;
        this.remoteStream = null;
        this.isJoinedRoom = false;
      }, (err) => {
        console.error('离开房间失败：', err);
      });
    },
    switchCamera() {
      const currentIndex = this.cameras.findIndex(v => v.deviceId === this.currentCameraDeviceId)
      const nextDevice = this.cameras[currentIndex >= this.cameras.length - 1 ? 0 : currentIndex + 1]
      this.client.switchDevice({
        type: 'video',
        deviceId: nextDevice.deviceId
      })
    },
    handleOff() {
      this.$dialog.confirm({
        title: '提示',
        message: '您确定要你开房间吗？',
      })
      .then(() => {
        this.client && this.client.leaveRoom(() => {
          this.$router.replace('/appointment-list')
        })
      })
    },
  }
};
</script>

<style lang="less" scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.chat {
  &-container {
    height: 100vh;
    position: relative;
  }
  &-main {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #313131;
  }
  &-btns {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .chat-btn {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &-btn {
    display: inline-block;
    padding: 8px;
    border-radius: 50%;
    background-color: #1989fa;
    color: #fff;
    font-size: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    .svg-icon {
      font-size: 24px;
    }
    &.danger {
      background-color: #ee0a24;
      padding: 16px;
    }
  }
  &-switch {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  &-self {
    position: absolute;
    bottom: 96px;
    right: 30px;
    border: 2px solid #fff;
    overflow: hidden;
    border-radius: 4px;
    width: 240px;
    height: 135px;
    .item-icon {
      font-size: 24px;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }
  }
  &-video {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .chat-self {
    width: 90px;
    height: 160px;
  }
}
</style>
